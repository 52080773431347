import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PriceHistoryBox from "./PriceHistoryBox";
import PriceHistoryBlog from "./PriceHistoryBlog";

import { TYPE_GOLD, TYPE_PLATINUM, TYPE_SILVER } from "../../constants";
import { getMetalData } from "../../graph/graphData";

const PriceHistory = ({ type = TYPE_GOLD, variant = "page" }) => {
    const {
        wpgraphql: {
            metalsData: {
                xau: currentGoldPrice,
                xag: currentSilverPrice,
                xpt: currentPlatinumPrice,
            },
        },
    } = useStaticQuery(graphql`
        query HeaderQuery {
            wpgraphql {
                metalsData {
                    xau
                    xag
                    xpt
                }
            }
        }
    `);

    const trend = (metal, currentPrice) => {
        const lastYearEndPrice = getMetalData(metal, currentPrice).slice(-2)[0];
        return (
            Math.round(
                ((currentPrice - lastYearEndPrice) / currentPrice) * 100 * 100
            ) / 100
        );
    };

    let currentPrice = currentGoldPrice;
    let title = "Vývoj cen zlata";
    if (type === TYPE_SILVER) {
        currentPrice = currentSilverPrice;
        title = "Vývoj cen stříbra";
    }

    if (type === TYPE_PLATINUM) {
        currentPrice = currentPlatinumPrice;
        title = "Vývoj cen platiny";
    }

    if (variant === "page") {
        return (
            <PriceHistoryBox
                type={type}
                title={title}
                currentPrice={currentPrice}
                trend={trend(type, currentPrice)}
            />
        );
    }

    return (
        <PriceHistoryBlog
            type={type}
            title={title}
            currentPrice={currentPrice}
            trend={trend(type, currentPrice)}
        />
    );
};

export default PriceHistory;
