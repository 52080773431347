import React from "react";
import style from "./history.module.sass";
import button from "../../components/Button/button.module.sass";
import { Link } from "gatsby";
import moment from "moment";
import Title from "../Title";
import classNames from "classnames";

import { TYPE_GOLD, TYPE_PLATINUM, TYPE_SILVER } from "../../constants";
import Graph from "../../graph/Graph";
import Quote from "../Quote";

const PriceHistoryBox = ({ currentPrice, trend, title, type }) => (
    <section className={style.historyGoldPrice}>
        <div className={style.historyGraph}>
            <Title type="h2" color={type}>
                {title}
            </Title>
            <p className={style.historyInfo__date}>
                Aktuální data k {moment().format("D. M. YYYY")}
            </p>
            <div className={style.historyInfo__trendContainer}>
                <span className={style.historyInfo__value}>
                 ${Math.round(currentPrice * 100) / 100}
                </span>
                {trend > 0 && (
                    <>
                        <span className={style.historyInfo__trend}>
                            {trend}%
                            <img
                                src={require("../../images/icons_new/caret_up.svg")}
                                alt=""
                            />
                            <abbr
                                title="Od počátku roku"
                                className={style.historyInfo__abbr}
                            >
                                YTD
                            </abbr>
                        </span>
                    </>
                )}
            </div>
            <Graph type={type} currentPrice={currentPrice} />
            <div className={style.historyInfo__content}>
                <p className={style.historyInfo__info}>
                    Ceny jsou v Amerických dolarech za trojskou unci.
                </p>
                {type === TYPE_SILVER && (
                    <p className={style.historyInfo__description}>
                        Je na vás, zda chcete blížící se situace využít ve svůj
                        prospěch a rozšířit své portfolio o stříbro.
                    </p>
                )}
                {type === TYPE_PLATINUM && (
                    <p className={style.historyInfo__description}>
                        Je na vás, zda chcete blížící se situace využít ve svůj
                        prospěch a rozšířit své portfolio o platinu.
                    </p>
                )}
                {type === TYPE_GOLD && (
                    <Link
                        to="/vyvoj-cen/"
                        className={classNames({
                            [button["buttonGold"]]: true,
                        })}
                    >
                        Spočítat investice
                    </Link>
                )}
            </div>
        </div>

        {type === TYPE_GOLD && (
            <Quote
                type={TYPE_GOLD}
                text="Zlato ruce nemá, ale všechno dokáže."
                name="Gruzínské přísloví."
            />
        )}
        {type === TYPE_SILVER && (
            <Quote
                type={TYPE_SILVER}
                text="Bojuj zbraněmi ze stříbra a vše přemůžeš."
                name="Latinské pořekadlo."
            />
        )}
        {type === TYPE_PLATINUM && (
            <Quote
                type={TYPE_PLATINUM}
                text="Pomáháme dělat dobrá rozhodnutí."
                name="KAPITOL"
            />
        )}
    </section>
);

export default PriceHistoryBox;
