import { TYPE_SILVER, TYPE_PLATINUM } from "../constants";

export const getRange = (start, max, iteration) => {
    let range = [];
    for (let n = start; n <= max; n += iteration) {
        range.push(n);
    }
    return range;
};

export const getMetalData = (metal, currentPrice) => {
    if (metal === TYPE_SILVER) {
        return [
            4.52,
            4.67,
            5.97,
            6.82,
            8.83,
            12.9,
            14.76,
            10.79,
            16.99,
            30.63,
            28.18,
            29.95,
            19.5,
            15.97,
            13.8,
            15.99,
            17.13,
            15.52,
            17.9,
            27.56,
            22.84,
            23.95,
            currentPrice,
        ];
    }

    if (metal === TYPE_PLATINUM) {
        return [
            480.0,
            598.0,
            813.0,
            861.0,
            1118.0,
            1534.0,
            898.0,
            1461.0,
            1755.0,
            1354.0,
            1527.0,
            1357.0,
            1206.0,
            891.55,
            903.1,
            937.48,
            799.66,
            976.52,
            1109.74,
            965.8,
            1068.03,
            currentPrice,
        ];
    }

    return [
        276.5,
        342.75,
        417.25,
        438.0,
        513.0,
        635.7,
        836.5,
        865.0,
        1104.0,
        1410.25,
        1574.5,
        1664.0,
        1201.5,
        1199.25,
        1060.2,
        1151.7,
        1296.5,
        1281.65,
        1523.0,
        1948.71,
        1829.6,
        1826.2,
        1991.8,
        currentPrice,
    ];
};

export const getPlotlyData = (metal, currentPrice) => {
    let dtick = 400;

    if (metal === TYPE_SILVER) {
        dtick = 4;
    }

    let metalColors = {
        gold: "#B29B5B",
        silver: "#9AAAB2",
        platinum: "#B5AEA4",
    };

    let metalColorsBg = {
        gold: "#E4D7BD",
        silver: "#9AAAB2",
        platinum: "#B5AEA4",
    };

    let actualYear = new Date().getFullYear();
    let yearRange = getRange(2001, actualYear, 1);
    let metalRange = getMetalData(metal, currentPrice);
    let firstValue = metalRange[0];
    let goldRangeToolTip = metalRange.map(
        value => (value = `$${Math.round(value * 100) / 100}`)
    );

    return {
        layout: {
            annotations: [
                {
                    x: actualYear,
                    y: currentPrice,
                    xref: "x",
                    yref: "y",
                    showarrow: true,
                    font: {
                        family: "Work Sans, Arial, sans-serif",
                        size: 14,
                        color: "#00212F",
                    },
                    align: "center",
                    arrowhead: 0,
                    arrowsize: 1,
                    arrowwidth: 1,
                    arrowcolor: metalColorsBg[metal],
                    ax: 20,
                    ay: -30,
                    borderwidth: 1,
                    borderpad: 3,
                    bgcolor: metalColorsBg[metal],
                    opacity: 0.9,
                },
            ],
            xaxis: {
                fixedrange: true,
                showgrid: false,
                ticklen: 8,
                tickcolor: "#9AAAB2",
                linewidth: 1,
                linecolor: "#9AAAB2",
                tick0: 2000,
                dtick: 4,
                tickfont: {
                    family: "Work Sans, Arial, sans-serif",
                    size: 11,
                    color: "#00212F",
                },
            },
            yaxis: {
                fixedrange: true,
                showgrid: true,
                ticklen: 8,
                tickcolor: "#9AAAB2",
                tickprefix: "$",
                linewidth: 1,
                linecolor: "#9AAAB2",
                tick0: 0,
                dtick: dtick,
                tickfont: {
                    family: "Work Sans, Arial, sans-serif",
                    size: 11,
                    color: "#00212F",
                },
            },
            margin: {
                t: 0,
                r: 0,
                b: 45,
                l: 45,
                pad: 0,
            },
            separators: ", ",
            showlegend: false,
            font: {
                size: 11,
                family: "Work Sans, Arial, sans-serif",
            },
            useResizeHandler: true,
            autosize: true,
            dragmode: "pan",
        },
        data: [
            {
                x: yearRange,
                y: metalRange,
                mode: "lines",
                type: "scatter",
                fill: "none",
                line: {
                    color: metalColors[metal],
                    size: 1,
                    width: 2,
                },
                hoverinfo: "none",
                hovertext: "",
            },

            {
                x: yearRange,
                y: metalRange,
                mode: "text",
                type: "scatter",
                hoverlabel: {
                    bgcolor: "#ffffff",
                    bordercolor: metalColors[metal],
                    font: {
                        size: 12,
                        color: metalColors[metal],
                    },
                },
                hoverinfo: "text",
                hovertext: goldRangeToolTip,
            },

            {
                x: [yearRange[0], actualYear],
                y: [firstValue, currentPrice],
                mode: "markers+text",
                text: [`$${Math.round(firstValue * 100) / 100}`],
                textposition: "top",
                textfont: {
                    size: 12,
                    color: "#4D5D66",
                },
                type: "scatter",
                marker: {
                    color: "#ffffff",
                    size: 12,
                    line: {
                        color: metalColors[metal],
                        width: 1,
                    },
                },
                hoverinfo: "none",
                hovertext: "",
            },
        ],
    };
};
