import React from "react";
import classNames from "classnames";
import { TYPE_GOLD, TYPE_SILVER, TYPE_PLATINUM } from "../../constants";

import style from "./quote.module.sass";

const Quote = ({ type = TYPE_GOLD, text, name }) => (
    <blockquote
        className={classNames({
            [style.quote]: true,
            [style.gold]: type === TYPE_GOLD,
            [style.silver]: type === TYPE_SILVER,
            [style.platinum]: type === TYPE_PLATINUM,
        })}
    >
        <p className={style.text}>{text}</p>
        <p className={style.name}>{name}</p>
    </blockquote>
);

export default Quote;
