import React from "react";
import Loadable from "react-loadable";
import styles from "./Graph.module.sass";
import loader from "./loader.svg";

import { getPlotlyData } from "./graphData";

const Plot = Loadable({
    loader: () => import("react-plotly.js"),
    loading: () => (
        <div className={styles.loading}>
            <img src={loader} alt="Loading" />
        </div>
    ),
});

export default ({ type, currentPrice }) => {
    const data = getPlotlyData(type, parseFloat(currentPrice));
    return typeof window !== "undefined" ? (
        <Plot
            style={{ width: "100%" }}
            className={styles.plot}
            data={data.data}
            config={{ displayModeBar: false, responsive: true }}
            useResizeHandler={true}
            layout={data.layout}
        />
    ) : null;
};
