import React from "react";
import classNames from "classnames";
import style from "./historyBlog.module.sass";
import button from "../../components/Button/button.module.sass";
import { Link } from "gatsby";
import moment from "moment";
import { TYPE_GOLD, TYPE_SILVER, TYPE_PLATINUM } from "../../constants";

import Graph from "../../graph/Graph";

const PriceHistoryBlog = ({ currentPrice, trend, title, type }) => (
    <div className={style.historyWrapper}>
        <section className={style.historyGoldPrice}>
            <div className={style.historyGoldPriceInner}>
                <div className={style.historyInfo}>
                    <div>
                        <h2
                            className={classNames({
                                [style.gold]: type === TYPE_GOLD,
                                [style.silver]: type === TYPE_SILVER,
                                [style.platinum]: type === TYPE_PLATINUM,
                            })}
                        >
                            Aktuální cena {type === TYPE_GOLD && "zlata"}
                            {type === TYPE_SILVER && "stříbra"}
                            {type === TYPE_PLATINUM && "platiny"}
                        </h2>
                        <p className={style.historyInfo__date}>
                            {moment().format("D. M. YYYY")}
                        </p>
                        <div className={style.historyInfo__trendContainer}>
                            <span className={style.historyInfo__value}>
                                ${Math.round(currentPrice * 100) / 100}
                            </span>
                            {trend > 0 && (
                                <>
                                    <span className={style.historyInfo__trend}>
                                        {trend}%
                                        <img
                                            src={require("../../images/icons_new/caret_up.svg")}
                                            alt=""
                                        />
                                        <abbr
                                            title="Od počátku roku"
                                            className={style.historyInfo__abbr}
                                        >
                                            YTD
                                        </abbr>
                                    </span>
                                </>
                            )}
                        </div>
                        <p className={style.historyInfo__description}>
                            {type === TYPE_GOLD &&
                                "Je jen na vás, zda chcete blížící se situace využít ve svůj prospěch a případně své portfolio o další zlato rozšířit."}
                            {type === TYPE_SILVER &&
                                "Je na vás, zda chcete blížící se situace využít ve svůj prospěch a rozšířit své portfolio o stříbro."}
                            {type === TYPE_PLATINUM &&
                                "Je na vás, zda chcete blížící se situace využít ve svůj prospěch a rozšířit své portfolio o platinu."}
                        </p>
                    </div>
                    <div className={style.historyInfo__buttonContainer}>
                        {type === TYPE_GOLD && (
                            <>
                                <Link
                                    to="/gold-form/"
                                    className={button.buttonGold}
                                >
                                    Máte zájem investovat do zlata?
                                </Link>
                                <Link
                                    to="/vyvoj-cen/"
                                    className={button.buttonOutlineGoldDark}
                                >
                                    Spočítat investice
                                </Link>
                            </>
                        )}
                        {type === TYPE_SILVER && (
                            <Link
                                to="/silver-form/"
                                className={button.buttonSilver}
                            >
                                Máte zájem investovat do stříbra?
                            </Link>
                        )}
                        {type === TYPE_PLATINUM && (
                            <Link
                                to="/platinum-form/"
                                className={button.buttonPlatinum}
                            >
                                Máte zájem investovat do platiny?
                            </Link>
                        )}
                    </div>
                </div>
                <div className={style.historyGraph}>
                    <Graph type={type} currentPrice={currentPrice} />
                    <div className={style.historyGraphInner}>
                        <p
                            className={classNames({
                                [style.historyGraph__title]: true,
                                [style.gold]: type === TYPE_GOLD,
                                [style.silver]: type === TYPE_SILVER,
                                [style.platinum]: type === TYPE_PLATINUM,
                            })}
                        >
                            {type === TYPE_GOLD &&
                                "Zlato a vývoj jeho ceny za posledních 20 let"}
                            {type === TYPE_SILVER &&
                                "Stříbro a vývoj jeho ceny za posledních 20 let"}
                            {type === TYPE_PLATINUM &&
                                "Platina a vývoj její ceny za posledních 20 let"}
                        </p>
                        <p className={style.historyGraph__description}>
                            Ceny jsou v Amerických dolarech za trojskou unci.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
);

export default PriceHistoryBlog;
